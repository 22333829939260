import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "cp-badges-container" }
const _hoisted_2 = {
  key: 0,
  class: "cp-badge cp-badge_test"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_title_return = _resolveComponent("title-return")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_text_datetime = _resolveComponent("text-datetime")!
  const _component_status_active = _resolveComponent("status-active")!
  const _component_text_number = _resolveComponent("text-number")!
  const _component_gui_form_checkbox = _resolveComponent("gui-form-checkbox")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_app_row = _resolveComponent("app-row")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, {
        grow: 0,
        class: "cp-admin-title-tab__wrapper"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tabs_nav, {
            items: _ctx.tabsNav,
            "active-id": _ctx.currentTab,
            params: { customer_id: this.$route.params.customer_id }
          }, null, 8, ["items", "active-id", "params"]),
          _createVNode(_component_title_return, {
            icon: "clients",
            "icon-size": "19",
            "total-items": _ctx.settings.table.totalItems,
            "is-add": true,
            "add-tooltip": "Добавить шаблон заказа",
            onClick: _ctx.returnBefore,
            onToAdd: _ctx.toAdd
          }, {
            "icon-badge": _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                (_ctx.isTest)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Test"))
                  : _createCommentVNode("", true)
              ])
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_ctx.titlePage), 1)
            ]),
            _: 1
          }, 8, ["total-items", "onClick", "onToAdd"])
        ]),
        _: 1
      }),
      (Object.keys(_ctx.settings.table).length === 0)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { "absolute-center": "" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (Object.values(_ctx.settings.table).length > 0)
        ? (_openBlock(), _createBlock(_component_app_table, {
            key: 1,
            class: "cp-margin__t--small grow-1",
            headers: _ctx.settings.table.titles,
            items: _ctx.settings.table.rows,
            page: _ctx.settings.table.pagination.currentPage,
            "total-pages": _ctx.settings.table.pagination.totalPages,
            "total-items": _ctx.settings.table.totalItems,
            "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
            onChangePage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
            onSelectAmount: _ctx.selectAmount
          }, {
            "item-name": _withCtx(({ value, item }) => [
              _createVNode(_component_router_link, {
                class: "app-table-link",
                to: { name: 'template_edit', params: { customerId: _ctx.$route.params.customerId, templateId: item.id } }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(value), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            "item-createdAt": _withCtx(({ value }) => [
              value
                ? (_openBlock(), _createBlock(_component_text_datetime, {
                    key: 0,
                    value: value,
                    "format-from": "YYYY-MM-DD",
                    "format-to": "DD.MM.YYYY"
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true)
            ]),
            "item-isActive": _withCtx(({ value }) => [
              _createVNode(_component_status_active, { "is-active": value }, null, 8, ["is-active"])
            ]),
            "item-gender": _withCtx(({ value }) => [
              _createElementVNode("div", null, _toDisplayString(_ctx.getGender(value)), 1)
            ]),
            "item-hasLmk": _withCtx(({ value }) => [
              _createElementVNode("div", null, _toDisplayString(_ctx.getLmkAndTraining(value)), 1)
            ]),
            "item-isTraining": _withCtx(({ value }) => [
              _createElementVNode("div", null, _toDisplayString(_ctx.getLmkAndTraining(value)), 1)
            ]),
            "item-ageTo": _withCtx(({ item }) => [
              _createElementVNode("span", null, _toDisplayString(item.ageFrom) + "-" + _toDisplayString(item.ageTo), 1)
            ]),
            "item-rate": _withCtx(({ item }) => [
              _createElementVNode("span", null, [
                _withDirectives(_createVNode(_component_text_number, {
                  value: item.rate
                }, null, 8, ["value"]), [
                  [_directive_tooltip, 'Ставка для исполнителя']
                ]),
                _createTextVNode(" /"),
                _withDirectives(_createVNode(_component_text_number, {
                  value: item.externalRate
                }, null, 8, ["value"]), [
                  [_directive_tooltip, 'Внешняя ставка']
                ])
              ])
            ]),
            "item-forLk": _withCtx(({ item, value }) => [
              _createVNode(_component_gui_form_checkbox, {
                "model-value": _ctx.getForLk(value),
                onUpdate: ($event: any) => (_ctx.forLkUpdate(item))
              }, null, 8, ["model-value", "onUpdate"])
            ]),
            _: 1
          }, 8, ["headers", "items", "page", "total-pages", "total-items", "page-items", "onSelectAmount"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}