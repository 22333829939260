
import { Component, Vue } from '@/lib/decorator';
import TitleReturn from '@/components/TitleReturn.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';

import CustomerEntityModule from '@/store/customers/entity';
import CustomersModule from '@/store/customers';
import CustomersTemplatesModule from '@/store/customers/templates';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import StatusActive from '@/components/table-items/StatusActive.vue';
import AppTable from '@/components/ui/table/Table.vue';
import TextNumber from '@/components/table-items/TextNumber.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import FormSimpleSwitch from '@/components/form/Switch/SimpleSwitch.vue';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import CustomersTemplatesEntityModule, { TextFieldsData } from '@/store/customers/templatesEntity';
import { TemplateTableItem } from '@/interfaces/models/template.interface';
import { GuiFormCheckbox, GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    AppCol,
    AppRow,
    TextNumber,
    AppTable,
    StatusActive,
    TextDatetime,
    TitleReturn,
    TabsNav,
    FormSimpleSwitch,
    GuiFormCheckbox,
  },
  beforeMount() {
    this.customerId = this.$route.params.customerId as string;
  },
})
export default class Vacancies extends Vue {
  customerId = '';

  get isTest(): boolean {
    return CustomerEntityModule.model.isTest;
  }

  get onlyActive(): boolean {
    return CustomersTemplatesModule.onlyActive;
  }

  get settings(): PageInterface {
    return CustomersTemplatesModule.pageSettings;
  }

  get vaccinationsList(): { id: string; name: string }[] {
    return CustomersTemplatesModule.vaccinationsList;
  }

  get titlePage(): string {
    return CustomersModule.pageSettings.titleEdit;
  }

  get tabsNav(): TabsNavInterface {
    return CustomerEntityModule.tabsNav;
  }

  get currentTab(): string {
    return CustomerEntityModule.allTabs.templates?.id;
  }

  get actionsHandler(): { additional: { [p: string]: string } } {
    return CustomersTemplatesModule.actionsHandler;
  }

  returnBefore(): void {
    this.$router.push({ name: 'customers' });
  }

  toAdd(): void {
    this.$router.push({ name: 'template_add', params: { customerId: this.customerId } });
  }

  selectAmount(value: string): void {
    CustomersTemplatesModule.updatePageAmountItems(value);
  }

  convertVaccinationRequirement(value: string): string {
    let name = '';
    this.vaccinationsList.map((item: { id: string; name: string }) => {
      if (item.id === value) {
        name = item.name;
      }
    });

    return name;
  }

  getGender(value: string): string {
    switch (value) {
      case 'f':
        return 'Женский';
      case 'm':
        return 'Мужской';
      default:
        return 'Не важно';
    }
  }

  getLmkAndTraining(value: boolean): string {
    switch (value) {
      case false:
        return '-';
      case true:
        return 'Требуется';
      default:
        return '-';
    }
  }

  getForLk(value: boolean): boolean {
    let testValue = value;

    return testValue;
  }

  async forLkUpdate(item: TemplateTableItem): Promise<void> {
    try {
      await CustomersTemplatesEntityModule.initEdit(item.id.toString());
      const transformedObjectSitizenship = {} as Record<string, string>;
      CustomersTemplatesEntityModule.model.citizenship.forEach((item: { id: number; value: string; name: string }) => {
        transformedObjectSitizenship[`citizenship[${item.id}]`] = item.id.toString();
      });

      const responseData: TextFieldsData = {
        name: item.name,
        isActive: +item.isActive,
        isTraining: +item.isTraining,
        hasLmk: +item.hasLmk,
        vaccinationRequirement: item.vaccinationRequirement,
        forLk: +!item.forLk,
        'age[from]': item.ageFrom,
        'age[to]': item.ageTo,
        serviceType: item.serviceTypeId,
        maxHours: item.maxHours,
        rate: item.rate,
        externalRate: item.externalRate,
        educationLink: CustomersTemplatesEntityModule.model.trainingWebLink,
        externalName: CustomersTemplatesEntityModule.model.externalName,
        unitType: item.unitTypeId,
        ...transformedObjectSitizenship,
      };

      if (item.gender) responseData['gender'] = item.gender;

      if (CustomersTemplatesEntityModule.model.competence?.id) {
        responseData['competence'] = +CustomersTemplatesEntityModule.model.competence.id;
      }

      await CustomersTemplatesEntityModule.saveUpdate({
        id: item.id.toString() as string,
        textFieldsData: responseData,
      });
    } catch (e) {
      console.debug('error');
    }
  }

  onlyActiveUpdated(value: boolean): void {
    this.$store.dispatch(CustomersTemplatesModule.pageSettings.actionPagination as string, 1);
    CustomersTemplatesModule.updateOnlyActive(value);
  }

  mounted(): void {
    CustomerEntityModule.initTabsItems();
    CustomerEntityModule.initTitle(this.customerId);
    CustomersTemplatesModule.initListById(this.customerId);
  }
}
